.SearchWrapper {
    flex: 1 1;
    position: relative;

    &Active {
        display: block!important;
        position: absolute;
        width: 90%;
        left: 5%;
    }
}

.SearchActiveBorder {
    transition: 0.3s;
    &:before {
        background-color: #fff;
        position: absolute;
        width: calc(100% + 20px);
        left: -10px;
        top: -10px;
        height: calc(100% + 20px);
        content: "";
        box-shadow: 0 4px 16px 2px rgb(0 26 52 / 16%);
        border-radius: 8px;
        z-index: 14;
        transition: 0.3s;
    }

}

.Search {
  border: 1px solid #D8D8D8;
  display: flex;
  border-radius: 4px;

  align-items: center;
  height: 48px;
  justify-content: space-between;

  position: relative;

  background-color: #fff;
  z-index: 14;
  input {
    border: none;
    outline: none;
    font-size: 15px;
    color: #9294A9;
    padding: 10px 15px;
    border-radius: 20px;
    width: 100%;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            border: none;
        }
    }
  }

  a {
    display: flex;
  }

  > svg {
    margin: 0 10px;
  }

  svg {
    cursor: pointer;
    min-width: 24px;
  }

    &Scrolled {
      height: 35px;

        &Landing {
            height: 56px;
        }

      input {
          padding: 5px 15px;
      }
  }
}

.Background {
    background-color: #000;
    opacity: 0.2;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh!important;
    z-index: 12;
    transition: 0.3s;
}

.Results {
    z-index: 14;
    top: 50px;
    min-width: 300px;
    background-color: #fff;
    position: absolute;
    width: calc(100% + 22px);
    box-shadow: 0 15px 16px 2px rgb(0 26 52 / 16%);
    border-radius: 0 0 8px 8px;
    padding: 15px 10px;
    left: -11px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    max-height: 80vh;
    overflow: auto;
    ul {
        width: 100%;
        li {
            padding: 5px 15px;
            border-bottom: 1px solid #d8d8d8;
            cursor: pointer;
            transition: 0.3s;



        &:last-child {
                border-bottom: none;
            }
        }
    }
}

.Item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13px;
    padding: 5px;
    width: 100%;
    border-radius: 10px;

    &Wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5px;
    }
    
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: #f9f9f9;
        }
    }
}

.ItemPhoto {
    max-width: 150px;
}

.Name {
    display: flex;
    color: #333;
    gap: 5px;
    font-size: 15px;
    flex-direction: column;
    flex: 1;
}


.Photo {
    color: #282f36;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    min-width: 60px;
}

.Price {
    font-weight: bold;
    color: #333;
}

.Art {
    font-size: 13px;
    font-weight: bold;
}

.Icon {
    fill: var(--main-theme);
    stroke-width: 2px;
}

.Close {
    margin: 0 10px;
}

.File {
    font-size: 13px;
}

@media only screen and (max-width: 991px) {
    .SearchWrapper {
        display: none;
        &Landing {
            display: unset;
        }
    }
}