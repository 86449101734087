.MobileMenu {

}

.TopSections {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: -30px;
}

.Links {
    display: flex;
    flex-direction: column;
    a {
        padding: 20px;
        color: #333;
        font-size: 17px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    li {
        display: flex;
        border-bottom: 1px solid #dfdfdf;
        &:first-child {
            border-top: 1px solid #dfdfdf;
        }
    }

}

.Info {
    color: #9198AD;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
}

.Soc {
    text-align: center;
    span {
        color: #9198AD;
        margin-bottom: 15px;
        display: inline-block;
    }
    &List {
        display: flex;
        gap: 12px;
        justify-content: center;
    }
}



.CartIcon {
    stroke: #93979b;
    stroke-width: 2px;
}

.Button {
    padding: 0 20px 20px;
}