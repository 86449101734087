.Footer {
    background: #040404;
    width: 100%;
    color: #fff;
    padding: 50px 0;

    a {
        color: #fff;
    }
}

.Logo {
    max-width: 140px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.Meta {
    font-size: 12px;
}

.Colomns {
    display: flex;
    margin-bottom: 35px;
    justify-content: space-between;
    > div {
        display: flex;
        flex-direction: column;
        width: 25%;

        &:first-child {
            justify-content: space-between;
        }
    }

    ul {
        display: flex;
        gap: 50px;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: 12px;
    }
}

.ColomnTitle {
    color: #9198AD;
    margin-bottom: 20px;
    display: block;
}

.Copy {
    border-top: 1px solid #9198AD;
    padding: 22px 0 26px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    color: #9198AD;
}

.Payments {
    display: flex;
    gap: 15px;
    align-items: center;
}

.Info {
    color: #9198AD;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.Soc {
    span {
        color: #9198AD;
        margin-bottom: 15px;
        display: inline-block;
    }
    &List {
        display: flex;
        gap: 12px;
    }
}

.Ozon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    img {
        border-radius: 5px;
    }
}

.Drive2 {
    img {
        border-radius: 5px;
    }
}

.Vidjets {
    display: flex;
    gap: 20px;
    flex-direction: column;
}


@media only screen and (max-width: 768px) {
    .Colomns {
        flex-direction: column;
    }
    .ColomnTitle {
        padding: 20px;
        font-size: 17px;
        margin: 0;
        color: #fff;
        position: relative;
        &:before {
            position: absolute;
            right: 15px;
            top: calc(50% - 4px);
            content: "";
            border: solid #fff;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            transition: 0.3s;

            transform: rotate(-45deg);
        }
    }
    .MenuBlock {
        width: 100%!important;

        border-top: 1px solid #9198AD;
        &:last-child {
            border-bottom: 1px solid #9198AD;
            position: relative;
            margin-bottom: 80px;
        }
        ul {
            display: none;
        }

        .Vidjets {
            margin: 20px 0;
            justify-content: center;
            left: 0;        
            align-items: center;
        }

        &Active {
            .ColomnTitle:before {
                transform: rotate(45deg);
            }
            ul {
                display: block;
                margin: 0 15px 15px;
                background-color: #1e2e4d;
                border-radius: 10px;
                li {

                    font-size: 16px;
                    margin: 0;
                    border-bottom: 1px solid #122038;
                    a {
                        padding: 15px 20px;
                        display: inline-block;
                        width: 100%;
                    }
                }
            }
        }
    }

    .Copy {
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        gap: 20px;

    }

    .Info {
        flex-direction: column;
        align-items: center;
    }
    .Colomns {
        > div {
            &:first-child {
                width: 100%;
                text-align: center;
                gap: 20px;
                margin-bottom: 30px;
            }
        }
       margin-bottom: 20px;
    }
    .SocList {
        justify-content: center;
    }

    .Privacy {
        text-align: center;
    }
    .Footer {
        padding: 30px 0;
    }
    .Colomns {
        ul {
            display: flex;
            align-items: center;
            margin: 0 auto;
            flex-direction: column;
            gap: 10px;
        }
    }
}