.Links {
    display: flex;
    list-style: none;
    flex: 1;
    min-width: 600px;
    max-width: 800px;
    margin-bottom: 0;
    justify-content: space-around;
    a {
        color: #fff;
        padding: 15px 0;
    }
}

@media (max-width: 1050px) {
    .Links {
        font-size: 13px;
    }
}


@media only screen and (max-width: 991px) {
    .Links {
        display: none;
    }
}