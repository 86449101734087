.TopSections {
    display: flex;
    min-height: calc(100vh - 150px);
}

.RootSections {
    width: 30%;
    background: #EEF0FC;
    padding: 44px;
    min-width: 350px;

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 32px;
    }

    li {
        cursor: pointer;
        padding-right: 30px;
        text-align: right;
        position: relative;

        svg {
            position: absolute;
            right: 0;
            top: 3px;
        }
    }

    a {
        color: #333;
    }
}

.Selected {
    color: var(--main-theme);
}

.ChildSections {
    padding: 44px;

    a {
        color: #333;
    }
}

.SectionOne {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 28px 0;
}

.SectionOneBrand {
    display: flex;
    gap: 10px;
    margin: 28px 0;
    flex-wrap: wrap;
    max-width: 800px;

    > a {
        color: #333;
        width: 180px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        align-items: center;
        text-align: center;
        div {
            display: flex;
            height: 120px;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            background-color: #EEF0FC;
            width: 100%;
            border-radius: 8px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
        span {
            color: #333;
            padding: 15px;
            width: 100%;
            font-weight: 600;
        }
    }
}

.SectionTwo {
    font-size: 14px;
    margin: 15px;
}

.Title {
    font-size: 20px;
    margin-bottom: 20px;
}

.Sections {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    gap: 15px;
    max-width: 870px;
}


.Section {
    transition: 0.3s;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    min-height: 100px;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            box-shadow: 0px 20px 40px rgb(0 0 0 / 15%);
        }
    }


    div {
        overflow: hidden;
        display: flex;
        img {
            max-height: 240px;
            width: 100%;
            border-radius: 8px;
        }
    }

  span {
      color: #333;
      padding: 15px;
      position: absolute;
      width: 100%;
      font-weight: 600;
    }
}


@media only screen and (max-width: 1200px) {
    .Sections {
        grid-template-columns: 1fr 1fr 1fr;
    }
}