.Sections {
  h2{
    margin-bottom: 50px;
  }
}

.Image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  opacity: 0.5;
}

.Text {
  z-index: 1;
  position: relative;
  margin: 40px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  height: calc(100% - 80px);
  box-sizing: border-box;

  h3 {
    width: 90%;
    color: #fff;
    left: 30px;
    font-weight: 600;
    font-size: 28px;
    top: 35px;
    margin: 0;
    padding: 0;
  }
  div {
    width: 90%;
    color: #fff;
    left: 30px;
    top: 80px;
    font-size: 17px;
    max-width: 400px;
    font-weight: normal;
    line-height: 1.7em;
  }
}

.SectionsList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #040404;
  color: #fff;

  > a {
    &:first-child {
      // height: 680px;
      background-position-x: 100%;
      grid-row-start: 1;
      grid-row-end: 3;


      &:before {
        background: linear-gradient(110.87deg, #040404 6.87%, #353535 97.81%);
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      // .Text {
      //   justify-content: flex-end;
      // }

      .Image {
          background-position: 0 0 ;
      }
    }

    &:nth-child(2) {
      background: linear-gradient(110.87deg, #040404 6.87%, #353535 97.81%);


    }

    &:nth-child(3) {
      background: linear-gradient(110.87deg, #040404 6.87%, #353535 97.81%);

    }

    &:last-child {
      grid-column-start: 2;
      grid-column-end: 4;
      background: linear-gradient(110.87deg, #040404 6.87%, #353535 97.81%);

    }


    min-height: 250px;
    background-position-x: 100%;
    background-position-y: 100%;
    position: relative;
    background-repeat: no-repeat;
    transition: 0.5s;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            z-index: 1;
            box-shadow: 0px 10px 20px rgb(0 0 0 / 15%);
            border-color: #fff;
        }
    }
  }
}

@media only screen and (max-width: 991px) {
  .SectionsList {
    grid-template-columns: 6fr 4fr 4fr;
  }
  .SectionsList > a {


    &:not(:first-child) {
      grid-column-start: 2;
      grid-column-end: 4;
    }

    &:last-child {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

@media only screen and (max-width: 768px) {


  .Sections {
    h2{
      margin-bottom: 30px;
    }
  }

  .SectionsList {
    grid-template-columns: 1fr 1fr;
    display: flex;
    flex-direction: column;
    > a{
      min-height: 250px;
      background-position-x: 50%;
      background-size: contain;
      &:first-child {
        // height: 40vw;
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 3;
      }

      &:not(:first-child) {
        grid-column-start: inherit;
        grid-column-end: inherit;
      }

      &:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
        // background-position-x: 100px;
      }

      h3 {
        left: 20px;
        font-weight: 600;
        font-size: 20px;
        top: 20px;
      }
    }
  }
}
