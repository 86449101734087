.CatalogButton {

    &Scrolled {
        max-height: 35px;

        @media (max-width: 768px) {
            &.CatalogButton {
                max-height: unset;
            }
        }
        @media (min-width: 769px) {
            height: 35px;
        }
    }
}


@media only screen and (max-width: 991px) {
    .CatalogButton {
        display: none;

        &Landing{
            display: flex;
            padding: 10px;
            height: 55px;
            width: 55px;
            justify-content: center;
            align-items: center;

            span {
                display: none;
            }
        }
    }
}